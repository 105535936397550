import { useRouter, usePathname } from "next/navigation"
import { useState, type MouseEvent } from "react"

import useMinicomDraftListQuery from "./useMinicomDraftListQuery"
import { type TConfirmParamsWithHandlers } from "@/types"

export default function useCreateMinicomRedirectHandler({
  canAccessMinicom,
}: {
  canAccessMinicom?: boolean
}) {
  const router = useRouter()
  const pathname = usePathname()
  const { data } = useMinicomDraftListQuery({
    enabled: canAccessMinicom,
  })
  const [confirmationModalOptions, setConfirmationModalOptions] = useState<
    TConfirmParamsWithHandlers | undefined
  >(undefined)

  function onClickHandler(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()
    const existingDraftCount = data?.items.length ?? 0

    if (pathname === "/mini-competition/create") return

    if (existingDraftCount > 0) {
      setConfirmationModalOptions({
        title: "Yakin Tambah Kompetisi ?",
        description: `Anda memiliki ${existingDraftCount} draf kompetisi yang belum selesai.`,
        cancelText: "Periksa Draf",
        confirmText: "Tambah Kompetisi",
        closable: true,
        onProceed: () => {
          setConfirmationModalOptions(undefined)
          router.push("/mini-competition/create")
        },

        onCancel: () => {
          setConfirmationModalOptions(undefined)
          router.push("/mini-competition/drafts")
        },
      })
      return
    }

    router.push("/mini-competition/create")
  }

  return {
    onClickHandler,
    setConfirmationModalOptions,
    confirmationModalOptions,
  }
}
