"use client"

import dynamic from "next/dynamic"
import { usePathname } from "next/navigation"
import { useCallback, useEffect, useState } from "react"
import {
  Sidebar as SidebarComponent,
  SidebarMenu,
  SidebarSubmenu,
} from "shared-ui"
import { isSubpath } from "shared-ui/src/isSubpath"

import { renderSidebarMenuItems } from "./config"
import { type SidebarMenuType } from "./type"
import useCreateMinicomRedirectHandler from "@/hooks/mini-competition/useCreateMinicomRedirectHandler"

const MiniCompetitionSnkModal = dynamic(
  () => import("gtp-ui/src/miniCompetitionSnkModal")
)

const ConfirmationModal = dynamic(
  () => import("shared-ui/src/components/ConfirmationModal")
)

const Sidebar = ({ canAccessMinicom }: { canAccessMinicom?: boolean }) => {
  const menuItems = renderSidebarMenuItems(canAccessMinicom)
  const [showMiniCompetitionSnK, setShowMiniCompetitionSnK] =
    useState<boolean>(false)
  const {
    onClickHandler,
    setConfirmationModalOptions: setMinicomModalOptions,
    confirmationModalOptions: minicomModalOptions,
  } = useCreateMinicomRedirectHandler({
    canAccessMinicom,
  })

  const pathname = usePathname() || undefined

  const checkDefaultOpenCollapse = (menu: SidebarMenuType) => {
    if (!Boolean(menu.submenuItems)) {
      return undefined
    }

    const activeMenu = menu.submenuItems?.find((subMenu) => {
      if (pathname && isSubpath(subMenu.route, pathname)) {
        return true
      }
    })
    return Boolean(activeMenu?.route)
  }

  const findMenu = useCallback(
    (menus: SidebarMenuType[], parentMenu?: SidebarMenuType) => {
      for (const menu of menus) {
        if (menu.route === pathname) {
          return {
            activeMenu: menu.title,
            parentMenu: parentMenu ? parentMenu.title : "",
          }
        }
        if (menu.submenuItems && menu.submenuItems.length > 0) {
          const submenu = menu.submenuItems.find(
            (submenuItem) => pathname && pathname.includes(submenuItem.route)
          )
          if (submenu) {
            return {
              activeMenu: submenu.title,
              parentMenu: menu.title,
            }
          }
          findMenu(menu.submenuItems, menu)
        }
      }
    },
    [pathname]
  )

  const miniCompetitionSnKApprovalStatus = globalThis.localStorage?.getItem(
    "miniCompetitionSnKApproval"
  )
  useEffect(() => {
    if (
      pathname?.startsWith("/mini-competition/") &&
      !miniCompetitionSnKApprovalStatus
    ) {
      setShowMiniCompetitionSnK(true)
    }
  }, [pathname, miniCompetitionSnKApprovalStatus])

  const handleSubmitMiniCompetitionSnK = (approved: string) => {
    globalThis.localStorage?.setItem("miniCompetitionSnKApproval", approved)
    setShowMiniCompetitionSnK(false)
  }

  return (
    <>
      <SidebarComponent
        className="h-fit w-[232px] flex-shrink-0 overflow-hidden rounded-6 border border-tertiary50 py-6"
        data-test="buyer-sidebar"
      >
        {Boolean(minicomModalOptions) && (
          <ConfirmationModal
            show
            options={minicomModalOptions}
            proceed={(proceed) =>
              proceed
                ? minicomModalOptions?.onProceed()
                : minicomModalOptions?.onCancel()
            }
            onClose={() => setMinicomModalOptions(undefined)}
          />
        )}

        {menuItems.map((menu: SidebarMenuType, index) => {
          return (
            <SidebarMenu
              title={menu.title}
              href={menu.route == "#" ? undefined : menu.route}
              key={index}
              classes={{
                menuTitle: "basis-[188px]",
              }}
              collapsible={Boolean(menu.submenuItems)}
              isDefaultOpenCollapse={checkDefaultOpenCollapse(menu)}
              Icon={menu.icon}
            >
              {menu.submenuItems &&
                menu.submenuItems.map(
                  (subMenu: SidebarMenuType, subMenuIndex: number) => {
                    return (
                      <SidebarSubmenu
                        title={subMenu.title}
                        href={subMenu.route}
                        key={subMenuIndex}
                        onClick={
                          subMenu.route === "/mini-competition/create"
                            ? onClickHandler
                            : undefined
                        }
                      />
                    )
                  }
                )}
            </SidebarMenu>
          )
        })}
      </SidebarComponent>
      {showMiniCompetitionSnK && (
        <MiniCompetitionSnkModal
          isOpen={showMiniCompetitionSnK}
          onSubmitSnkModal={handleSubmitMiniCompetitionSnK}
        />
      )}
    </>
  )
}

export default Sidebar
