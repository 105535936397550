export const renderSidebarMenuItems = (canAccessMinicom?: boolean) => {
  return [
    {
      route: "#",
      title: "Transaksi",
      isParent: true,
      submenuItems: [
        {
          route: "/negotiation",
          title: "Negosiasi",
        },
        {
          route: "/review-ppk",
          title: "Persetujuan PPK",
        },
        {
          route: "/orders",
          title: "Pesanan",
        },
      ],
    },
    ...(canAccessMinicom
      ? [
          {
            route: "#",
            title: "Mini Kompetisi",
            isParent: true,
            submenuItems: [
              {
                route: "/mini-competition/create",
                title: "Tambah Kompetisi",
              },
              {
                route: "/mini-competition/drafts",
                title: "Draf Kompetisi",
              },
              {
                route: "/mini-competition/my-competition",
                title: "Kompetisi Saya",
              },
            ],
          },
        ]
      : []),
    { route: "/chat", title: "Chat" },
  ]
}
