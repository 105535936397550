import { graphql } from "@/federatedGql"

export const MINICOM_DRAFT_LIST_QUERY = graphql(`
  query MinicomDraftList($input: DraftListInput!) {
    minikomDraftList(input: $input) {
      ... on DraftListResponse {
        items {
          id
          lastUpdatedAt
          title
          total
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)
