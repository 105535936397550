import { createQuery } from "react-query-kit"
import { type GenericExtractor } from "shared-utils"

import { MINICOM_DRAFT_LIST_QUERY } from "@/app/(navigation-layout)/(sidebar-layout)/mini-competition/drafts/serverRequests/minicomDraftListServerQuery/federated/queries"
import {
  type MinicomDraftListQuery,
  type MinicomDraftListQueryVariables,
} from "@/federatedGql/graphql"
import { federatedGqlClient } from "@/utils/graphqlClient"

type TMinicomDraftListResponse = GenericExtractor<
  MinicomDraftListQuery["minikomDraftList"]
>

const useMinicomDraftListQuery = createQuery<
  TMinicomDraftListResponse,
  MinicomDraftListQueryVariables
>({
  primaryKey: "minicomDraftList",
  queryFn: async () => {
    const res = await federatedGqlClient.request(MINICOM_DRAFT_LIST_QUERY, {
      input: {
        pagination: {
          page: 1,
          perPage: 5,
        },
      },
    })
    if (res?.minikomDraftList?.__typename === "GenericError") {
      throw new Error(res.minikomDraftList.code || "Error")
    }
    return res.minikomDraftList
  },
})

export default useMinicomDraftListQuery
